import { useWeb3Store } from "@/store/web3";
import { web3 } from "@/web3";

const sepoliaContractAddress = "0x411023F18b1393fbc648109d32A51f79cB818628";
const mainnetContractAddress = "0xB452feCFAAAe934a5C9655cf651a86Cb0E624613";

const abi = [
  {
    inputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    name: "ticketsPurchased",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
    constant: true,
  },
];

const getWalletPurchases = async ({ eventId }) => {
  const walletAddress = useWeb3Store().walletAddress;
  if (!walletAddress) throw new Error("No wallet connection found");

  const contractAddress =
    useWeb3Store().network.name === "sepolia"
      ? sepoliaContractAddress
      : mainnetContractAddress;

  const contract = new web3.eth.Contract(abi, contractAddress);

  try {
    const walletPurchases = await contract.methods
      .ticketsPurchased(walletAddress, eventId)
      .call();
    return walletPurchases;
  } catch (error) {
    console.error(error);
  }
};

export default getWalletPurchases;
