import { useApesStorageApi } from "@/api/useApesStorageApi";
import { useToast } from "@/composables/useToast";
import { useToastTransaction } from "@/composables/useToast/useToastTransaction";
import { captureError } from "@/helpers/captureError";
import { useWeb3Store } from "@/store/web3";
import { web3 } from "@/web3";

const sepoliaContractAddress = "0x411023F18b1393fbc648109d32A51f79cB818628";
const mainnetContractAddress = "0xB452feCFAAAe934a5C9655cf651a86Cb0E624613";

const abi = [
  {
    inputs: [
      {
        internalType: "uint256",
        name: "eventId",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "price",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "timeOut",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "maxPerWallet",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "recipient",
        type: "address",
      },
      {
        internalType: "bytes",
        name: "signature",
        type: "bytes",
      },
    ],
    name: "purchaseTickets",
    outputs: [],
    stateMutability: "payable",
    type: "function",
    payable: true,
  },
];

const buyTicket = async ({ eventId, amount }) => {
  const walletAddress = useWeb3Store().walletAddress;
  if (!walletAddress) throw new Error("No wallet connection found");

  const contractAddress =
    useWeb3Store().network.name === "sepolia"
      ? sepoliaContractAddress
      : mainnetContractAddress;

  const contract = new web3.eth.Contract(abi, contractAddress);

  let price = "";
  let signature = "";
  let timeOut = 0;
  let maxPerWallet = 0;

  // get buy params from backend
  try {
    const res = await useApesStorageApi({
      throw: true,
      toast: { error: true },
    }).exec({
      url: "/tickets/purchase-signature",
      method: "POST",
      data: {
        id: eventId,
        amount,
        wallet: walletAddress,
      },
    });
    if (res) {
      price = res.price;
      signature = res.signature;
      timeOut = res.timeOut;
      maxPerWallet = res.maxPerWallet;
    }
  } catch (error) {
    captureError(error);
    return;
  }

  try {
    const gas = await contract.methods
      .purchaseTickets(
        eventId,
        amount,
        price,
        timeOut,
        maxPerWallet,
        walletAddress,
        signature
      )
      .estimateGas({
        from: walletAddress,
        value: price,
      });
    await useToastTransaction(
      contract.methods.purchaseTickets(
        eventId,
        amount,
        price,
        timeOut,
        maxPerWallet,
        walletAddress,
        signature
      ),
      {
        text: "Buying ticket",
        successText: `Success! You bought ${amount} ticket${
          amount > 1 ? "s" : ""
        }`,
        sendOptions: {
          gas,
          from: walletAddress,
          value: price,
        },
      }
    );
  } catch (error) {
    captureError(error);
    useToast().error({
      title: error.message,
    });
  }
};

export default buyTicket;
