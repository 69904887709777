import { useWeb3Store } from "@/store/web3";
import { web3 } from "@/web3";

const abi = [
  {
    inputs: [{ internalType: "uint256", name: "id", type: "uint256" }],
    name: "totalSupply",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
];

const sepoliaContractAddress = "0xC85b725E4e48290182B3144AC04affDEb350ED5f";
const mainnetContractAddress = "0xa417E5aaCc260E710e66800cC7d02a9E2FCf27e5";
const getTicketsTotalSupply = async ({ eventId }) => {
  let contractAddress = mainnetContractAddress;
  if (useWeb3Store().network.name === "sepolia") {
    contractAddress = sepoliaContractAddress;
  }

  const contract = new web3.eth.Contract(abi, contractAddress);
  try {
    const totalSupply = await contract.methods.totalSupply(eventId).call();
    return totalSupply;
  } catch (error) {
    console.error(error);
  }
};

export default getTicketsTotalSupply;
