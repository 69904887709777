import buyTicket from "./buyTicket";
import getTicketsTotalSupply from "./getTicketsTotalSupply";
import getWalletPurchases from "./getWalletPurchases";
import bid from "./bid";
import getAuctionData from "./getAuctionData";
export const auctionContractAddress = "0x3D1c49D14f2338159cCe546C15EDbcE1e607212F";

export {
  buyTicket,
  getTicketsTotalSupply,
  getWalletPurchases,
  bid,
  getAuctionData,
};
