<template>
  <div class="event-page">
    <div class="event-page__logo">
      <img
        src="@/assets/img/logo.png"
        alt="Logo"
      >
    </div>
    <hgroup class="event-page__hero">
      <h1>Get Ready to Go Mega?</h1>
      <p>
        Join the Bulls and Apes Community for the Mega Meet Up event in Vegas!
      </p>
    </hgroup>
    <ConnectWallet v-if="!isConnected" />
    <div
      v-else
      class="event-page__action"
    >
      <h3>
        Tickets
        <br>
        <span v-if="ticketState.loading">Loading...</span>
        <span v-else>
          <span v-if="ticketState.ticketsLeft > 0">
            {{ ticketState.ticketsLeft }}
            left
          </span>
          <span v-else> (Sold Out)</span>
        </span>
      </h3>
      <div class="event-page__controllers">
        <button
          type="button"
          class="event-page__btn"
          :disabled="ticketState.amount <= 1"
          @click="ticketsController('sub')"
        >
          -
        </button>
        <input
          v-model="ticketState.amount"
          placeholder="Number of Tickets"
          class="event-page__controllers__input"
          type="number"
          min="1"
          max="10"
        >
        <button
          type="button"
          class="event-page__btn"
          :disabled="ticketState.amount >= ticketState.ticketsLeft || ticketState.amount >= 4 - ticketState.bought"
          @click="ticketsController('add')"
        >
          +
        </button>
      </div>
      <button
        :disabled="isMintDisabled || ticketState.amount > 4 - ticketState.bought"
        style="width: 100%"
        class="event-page__btn"
        @click="mint"
      >
        <span v-if="mintState.loading || ticketState.loading">Loading...</span>
        <span v-else-if="ticketState.bought == 4">
          Max 4 tickets purchased
        </span>
        <span v-else>
          Mint {{ ticketState.amount }} Ticket{{
            ticketState.amount > 1 ? "s" : ""
          }}
          <br>
          ({{ mintPrice }})
        </span>
      </button>
      <p v-if="ticketState.bought < 4">
        You Have {{ ticketState.bought }} Tickets
      </p>
    </div>
    <h2 class="event-page__footer">
      LAS VEGAS, SATURDAY, MAY 20th
    </h2>
  </div>
</template>

<script setup>
import { useApesStorageApi } from "@/api/useApesStorageApi";
import ConnectWallet from "@/components/BOTB/ConnectWallet.vue";
import { buyTicket, getTicketsTotalSupply,getWalletPurchases } from "@/functions/events";
import { useWeb3Store } from "@/store/web3";
import { computed, ref, watch } from "vue";

const mintState = ref({
  loading: false,
  error: null,
});
const ticketState = ref({
  weiPrice: null,
  ethPrice: null,
  ticketsLeft: 0,
  maxTickets: 101,
  amount: 1,
  loading: true,
  error: null,
  bought: 0,
});

// get ticket price from API
const getTicketsInfo = async () => {
  try {
    const res = await useApesStorageApi({
      throw: true,
      toast: { error: true },
    }).exec({
      url: "/tickets/wallet-price",
      params: {
        wallet: useWeb3Store().walletAddress,
      },
    });

    ticketState.value.weiPrice = res.price;
    ticketState.value.ethPrice = res.priceInETH;
    // TODO: update bought tickets state from API
    const boughtTickets = await getWalletPurchases({eventId: 2})
    ticketState.value.bought = boughtTickets
  } catch (error) {
    console.error(error);
    ticketState.value.error = error.message;
  }
};

const mintPrice = computed(() => {
  if (!ticketState.value.ethPrice) return 0;
  if (!ticketState.value.amount) return 0;
  return (ticketState.value.ethPrice * ticketState.value.amount).toString().slice(0, 4) + " ETH";
});

// check is Wallet Connected
const isConnected = computed(() => {
  return useWeb3Store().isConnected;
});
const chainId = computed(() => {
  return useWeb3Store().network.chainId;
});

const ticketsController = (type) => {
  if (type === "add") {
    if (ticketState.value.amount < ticketState.value.ticketsLeft && ticketState.value.amount < 4 - ticketState.value.bought)
      ticketState.value.amount++;
  } else {
    if (ticketState.value.amount > 1) ticketState.value.amount--;
  }
};

const isMintDisabled = computed(() => {
  return (
    ticketState.value.amount > ticketState.value.ticketsLeft ||
    ticketState.value.amount < 1 ||
    ticketState.value.ethPrice === null ||
    mintPrice.value <= 0 ||
    mintState.value.loading ||
    ticketState.value.loading
  );
});

// add mint function
const mint = async () => {
  if (isMintDisabled.value) return;
  mintState.value.loading = true;
  await buyTicket({
    amount: ticketState.value.amount,
    eventId: 2,
  });
  mintState.value.loading = false;
  await getTotalSupply();
  await getTicketsInfo();
};

// get total supply of tickets
const getTotalSupply = async () => {
  try {
    const totalSupply = await getTicketsTotalSupply({
      eventId: 2,
    });
    ticketState.value.ticketsLeft = ticketState.value.maxTickets - +totalSupply;
  } catch (error) {
    console.error(error);
  }
};

watch([isConnected, chainId], () => {
  if (isConnected.value && typeof chainId.value === "number") {
    Promise.all([getTicketsInfo(), getTotalSupply()])
      .then(() => {
        ticketState.value.loading = false;
      })
      .catch((error) => {
        console.error(error);
        ticketState.value.loading = false;
        ticketState.value.error = error.message;
      });
  }
});
</script>

<style lang="scss">
@import "@/assets/scss/mega-meetup.scss";
</style>
